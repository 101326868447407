var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('b-card',[_c('b-alert',{staticClass:"p-1 text-center",attrs:{"show":"","variant":"warning"}},[_vm._v("Po spremembi oglasa, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe niso vidne, poskusite osvežiti spletno stran.")]),_c('b-row',[_c('b-col',{attrs:{"align":"right"}},[(!_vm.object.approved)?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.approveClassifiedAd(_vm.object.id)}}},[_vm._v("Potrditev oglasa")]):_c('b-button',{attrs:{"disabled":"","variant":"success"}},[_vm._v("Oglas je potrjen")])],1)],1),_c('h3',{staticClass:"mt-2"},[_vm._v("1. Izberite kategorijo")]),_c('validation-observer',{ref:"validate"},[_c('b-form',{staticClass:"form"},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Tip kategorije"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"4","lg":"3"}},[_c('validation-provider',{attrs:{"name":"tip kategorije","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.typeCategorys,"label":"title","reduceField":"id","clearable":false,"text":"Vrsta oglasa"},model:{value:(_vm.object.listing_type),callback:function ($$v) {_vm.$set(_vm.object, "listing_type", $$v)},expression:"object.listing_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Kategorija"}},[_c('validation-provider',{attrs:{"name":"kategorija","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-none d-md-flex flex-wrap"},_vm._l((_vm.categoriesOptions),function(item){return _c('b-row',{key:item.id},[_c('b-col',[_c('b-form-radio',{staticClass:"m-1",attrs:{"value":item.id},model:{value:(_vm.object.category_id),callback:function ($$v) {_vm.$set(_vm.object, "category_id", $$v)},expression:"object.category_id"}},[_vm._v(_vm._s(item.title))])],1)],1)}),1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"3"}},[_c('Select',{staticClass:"d-md-none",attrs:{"options":_vm.categoriesOptions,"label":"title","reduceField":"id","clearable":false,"text":"Kategorija"},model:{value:(_vm.object.category_id),callback:function ($$v) {_vm.$set(_vm.object, "category_id", $$v)},expression:"object.category_id"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('h3',{staticClass:"mt-2"},[_vm._v("2. Izpolnite podatke")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Stanje"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"4","lg":"6"}},[_c('validation-provider',{attrs:{"name":"stanje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.types,"label":"title","reduceField":"id","clearable":false,"text":"Stanje"},model:{value:(_vm.object.condition),callback:function ($$v) {_vm.$set(_vm.object, "condition", $$v)},expression:"object.condition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Naslov"}},[_c('validation-provider',{attrs:{"name":"naslov oglasa","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"NASLOV OGLASA"},model:{value:(_vm.object.title),callback:function ($$v) {_vm.$set(_vm.object, "title", $$v)},expression:"object.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Opis"}},[_c('validation-provider',{attrs:{"name":"opis","rules":"required|max:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaEditor',{model:{value:(_vm.object.content),callback:function ($$v) {_vm.$set(_vm.object, "content", $$v)},expression:"object.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.object.listing_type !== 4)?_c('b-form-group',{attrs:{"label":"Cena"}},[_c('validation-provider',{attrs:{"name":"cena","rules":"required|max_value:10000000|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"step":"0.01","type":"number","value":_vm.price,"placeholder":"CENA"},on:{"input":_vm.changePrice}})],1)],1),_c('b-col',{staticClass:"text-left mt-1 mt-sm-0",attrs:{"cols":"12","sm":"5"}},[_c('b-form-radio',{attrs:{"name":"agreement","value":true},model:{value:(_vm.object.price_offer),callback:function ($$v) {_vm.$set(_vm.object, "price_offer", $$v)},expression:"object.price_offer"}},[_vm._v("PO DOGOVORU")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2020914950)})],1):_vm._e(),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Regija"}},[_c('validation-provider',{attrs:{"name":"regija","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"regija","placeholder":"Regija"},model:{value:(_vm.object.region),callback:function ($$v) {_vm.$set(_vm.object, "region", $$v)},expression:"object.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"text-left mt-1 mt-lg-0",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Kraj"}},[_c('validation-provider',{attrs:{"name":"kraj","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kraj","placeholder":"Kraj"},model:{value:(_vm.object.town),callback:function ($$v) {_vm.$set(_vm.object, "town", $$v)},expression:"object.town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"mt-2"},[_vm._v("Kontaktni podatki")]),_c('b-form-group',{attrs:{"label":"Ime"}},[_c('b-form-input',{attrs:{"readonly":"","placeholder":"IME"},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}})],1),_c('b-form-group',{attrs:{"label":"E-Naslov"}},[_c('b-form-input',{attrs:{"readonly":"","placeholder":"E-NASLOV"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('b-form-group',{attrs:{"label":"Kontaktna številka"}},[_c('b-form-input',{attrs:{"readonly":"","placeholder":"KONTAKTNA ŠTEVILKA"},model:{value:(_vm.object.telephone_number),callback:function ($$v) {_vm.$set(_vm.object, "telephone_number", $$v)},expression:"object.telephone_number"}})],1),_c('b-form-group',{attrs:{"align-v":"center"}},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v("UREDI OGLAS")])],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('ImageSelector',{ref:"imageSelector",attrs:{"main_photo":_vm.object.main_photo,"additional_photos":_vm.object.additional_photos,"type":"edit"},on:{"update-main-photo":_vm.updateMainPhoto}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }