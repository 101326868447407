<template>
    <b-overlay :show="showLoader">
        <b-card>
            <b-alert class="p-1 text-center" show variant="warning">Po spremembi oglasa, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe niso vidne, poskusite osvežiti spletno stran.</b-alert>
            <b-row>
                <b-col align="right">
                    <b-button v-if="!object.approved" variant="success" @click="approveClassifiedAd(object.id)">Potrditev oglasa</b-button>
                    <b-button v-else disabled variant="success">Oglas je potrjen</b-button>
                </b-col>
            </b-row>
            <h3 class="mt-2">1. Izberite kategorijo</h3>
            <validation-observer ref="validate">
                <b-form class="form">
                    <b-form-group class="mt-1" label="Tip kategorije">
                        <b-row>
                            <b-col cols="12" sm="8" md="4" lg="3">
                                <validation-provider #default="{ errors }" name="tip kategorije" rules="required">
                                    <Select v-model="object.listing_type" :options="typeCategorys" label="title" reduceField="id" :clearable="false" text="Vrsta oglasa"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group label="Kategorija">
                        <validation-provider #default="{ errors }" name="kategorija" rules="required">
                            <div class="d-none d-md-flex flex-wrap">
                                <b-row v-for="item in categoriesOptions" :key="item.id">
                                    <b-col>
                                        <b-form-radio class="m-1" v-model="object.category_id" :value="item.id">{{item.title}}</b-form-radio>
                                    </b-col>
                                </b-row>
                            </div>

                            <b-row>
                                <b-col cols="12" sm="8" md="3">
                                    <Select class="d-md-none" v-model="object.category_id" :options="categoriesOptions" label="title" reduceField="id" :clearable="false" text="Kategorija"/>
                                </b-col>
                            </b-row>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <h3 class="mt-2">2. Izpolnite podatke</h3>
                    <b-row>
                        <b-col cols='12' lg='6'>
                            <b-form-group class="mt-1" label="Stanje">
                                <b-row>
                                    <b-col cols="12" sm="8" md="4" lg="6">
                                        <validation-provider #default="{ errors }" name="stanje" rules="required">
                                            <Select v-model="object.condition" :options="types" label="title" reduceField="id" :clearable="false" text="Stanje"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group label="Naslov">
                                <validation-provider #default="{ errors }" name="naslov oglasa" rules="required|max:100">
                                    <b-form-input id="title" v-model="object.title" placeholder="NASLOV OGLASA"/>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group label="Opis">
                                <validation-provider #default="{ errors }" name="opis" rules="required|max:5000">
                                    <TextAreaEditor v-model="object.content" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group v-if="object.listing_type !== 4" label="Cena">
                                <validation-provider #default="{ errors }" name="cena" rules="required|max_value:10000000|min_value:0">
                                    <b-row align-v="center">
                                        <b-col cols="12" sm="6">
                                            <b-input-group class="input-group-merge" append="€">
                                                <b-form-input step="0.01" type="number" :value="price" v-on:input="changePrice" placeholder="CENA"/>
                                            </b-input-group>
                                        </b-col>
                                        <b-col cols="12" sm="5" class="text-left mt-1 mt-sm-0">
                                            <b-form-radio name="agreement" v-model="object.price_offer" :value="true">PO DOGOVORU</b-form-radio>
                                        </b-col>
                                    </b-row>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-row align-v="center">
                                <b-col cols="12" lg="6">
                                    <b-form-group label="Regija">
                                        <validation-provider #default="{ errors }" name="regija" rules="required|max:30">
                                            <b-form-input id="regija" v-model="object.region" placeholder="Regija"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" lg="6" class="text-left mt-1 mt-lg-0">
                                    <b-form-group label="Kraj">
                                        <validation-provider #default="{ errors }" name="kraj" rules="required|max:30">
                                            <b-form-input id="kraj" v-model="object.town" placeholder="Kraj"/>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <h3 class="mt-2">Kontaktni podatki</h3>
                            <b-form-group label="Ime">
                                <b-form-input readonly v-model="user.title" placeholder="IME"/>
                            </b-form-group>
                            <b-form-group label="E-Naslov">
                                <b-form-input readonly v-model="user.email" placeholder="E-NASLOV"/>
                            </b-form-group>
                            <b-form-group label="Kontaktna številka">
                                <b-form-input readonly v-model="object.telephone_number" placeholder="KONTAKTNA ŠTEVILKA"/>
                            </b-form-group>
                            <b-form-group align-v="center">
                                <b-row>
                                    <b-col>
                                        <b-button variant="secondary" @click.prevent="validationForm">UREDI OGLAS</b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                        <b-col cols='12' lg='6'>
                            <ImageSelector ref="imageSelector" :main_photo="object.main_photo" :additional_photos="object.additional_photos" type="edit" @update-main-photo="updateMainPhoto"/>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>

    </b-overlay>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, maxValue, minValue, max } from '@validations'
    import TextAreaEditor from '@/views/Components/TextAreaEditor'
    import ImageSelector from '@/views/Components/ImageSelector'
    import {BOverlay, BCard, BAlert, BRow, BCol, BFormGroup, BFormInput, BFormRadio, BButton, BInputGroup, BForm} from 'bootstrap-vue'
    import Select from '@/views/Components/Select.vue'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            TextAreaEditor,
            ImageSelector,
            BOverlay,
            BCard,
            BAlert,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormRadio,
            BButton,
            BInputGroup,
            BForm,
            Select
        },
        data() {
            return {
                object: {
                    author_id: '',
                    date_posted: '',
                    category_id: '',
                    approved: false,
                    title: '',
                    content: '',
                    listing_type: '',
                    condition: '',
                    price: '',
                    price_offer:false,
                    additional_photos:[],
                    region: '',
                    town : '',
                    main_photo: ''
                },
                user: {
                    name: '',
                    surname: '',
                    email:'',
                    telephone_number: '',
                    title: ''
                },
                regions: [{ title: 'Podravska'}],
                places: [{ title: 'Maribor'}],
                typeCategorys: [
                    {id: 0, title: 'PRODAM'},
                    {id: 1, title: 'KUPIM'},
                    {id: 2, title: 'ZAMENJAM'},
                    {id: 3, title: 'ODDAM'},
                    {id: 4, title: 'PODARIM'}
                ],
                types: [
                    {id: 0, title: 'NOVO'},
                    {id: 1, title: 'RABLJENO'},
                    {id: 2, title: 'POŠKODOVANO'}
                ],
                categoriesOptions: [],
                categories: [],
                selectedCategory: false,
                currentActiveCategory: null,
                required,
                email,
                maxValue,
                minValue,
                max,
                showLoader: false,
                categoryRows: [],
                coll: false,
                price : 0
            }
        },
        methods: {
            validationForm() {
                this.$refs.validate.validate().then(success => {
                    if (success) {
                        this.editClassifiedAd()
                    }
                })
            },
            changePrice(val) {
                this.price = val
                this.object.price = Number(Number(val * 100).toFixed(0))
            },
            updateMainPhoto(photo) {
                this.object.main_photo = photo
            },
            async editClassifiedAd() {
                this.showLoader = true

                try {
                    const imageUploadResult = await this.$refs.imageSelector.UploadImagesToAPI()
                    this.showLoader = true
                    if (this.object.main_photo === '') {
                        this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                        return
                    }

                    if (imageUploadResult) {
                        this.object.date_posted = new Date()
                        this.object.author_id = ''
                        this.object.price = parseFloat(parseFloat(this.object.price).toFixed(2))

                        await this.$http.patch(`/api/management/v1/c2c/${this.$route.params.ad_id}`, this.object)

                        this.$printSuccess('Oglas je posodobljen')
                        setTimeout(() => {
                            this.$router.push({name: 'admin-classified-ad-list'})
                        }, 1000)

                    } else {
                        this.$printError('Prišlo je do napake pri posodabljanju oglasa')
                    }
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            },
            async getAd() {
                try {
                    const adResponse = await this.$http.get(`/api/management/v1/c2c/${this.$route.params.ad_id}`)
                    this.object = adResponse.data

                    const adAuthorResponse = await this.$http.get(`/api/management/v1/user/${this.object.author.id}`)
                    this.user = adAuthorResponse.data
                    this.user.title = `${this.user.name  } ${  this.user.surname}`

                } catch (error) {
                    this.$printError(`Pri nalaganju oglasov je prišlo do napake!\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            getCategories() {
                const thisIns = this
                thisIns.categoryRows = []
                thisIns.showLoader = true
                const loadPromise = this.$http.get('/api/management/v1/c2c/category/')
                loadPromise.then(function(response) {
                    if (response.data.length > 0) {
                        thisIns.categories = response.data
                        for (const item of response.data) {
                            if (item.children.length > 0) {
                                for (const children of item.children) thisIns.categoriesOptions.push(children)
                            }
                        }
                    }
                    thisIns.showLoader = false
                }).catch(function(error) {
                    thisIns.showLoader = false
                    thisIns.$printError(error.response.data)
                })
            },
            approveClassifiedAd(id) {
                const thisIns = this
                thisIns.showLoader = true

                this.$http.post(`/api/management/v1/c2c/approve/${id}`)
                    .then(function() {
                        thisIns.$printSuccess('Oglas je potrjen')
                        thisIns.getAd()
                        thisIns.showLoader = false
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                        thisIns.showLoader = false
                    })
            },
            setPrice() {
                this.price = this.object.price / 100
            }
        },
        async mounted() {
            if (this.$store.state.user.userData !== null) {
                this.object.name = `${this.$store.state.user.userData.name  } ${  this.$store.state.user.userData.surname}`
                this.object.emailPost = this.$store.state.user.userData.email
                this.object.phone = this.$store.state.user.userData.telephone_number
            }
            await this.getCategories()
            await this.getAd()
            await this.setPrice()
        },
        watch: {
            'price'(val) {
                if (val > 0) this.object.price_offer = false
                else if (val === 0) this.object.price_offer = true
            },
            'object.price_offer'(val) {
                if (val) {
                    this.price = 0
                    this.object.price = 0
                }
            },
            'object.listing_type'(val) {
                if (val === 4) {
                    this.object.price_offer = true
                }
            }
        }
    }
</script>

<style scoped>
.filter {
  background-color: #72A5D8;
  padding: 1rem;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
}
.radio {
  position: relative;
  border: 2px solid #72A5D8;
  box-sizing: border-box;
  border-radius: 42px;
  padding: 1rem;
  color: #72A5D8;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  margin: 0.5rem;
}
.check-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  cursor: pointer;
}


.categories a {
  background-color: #E6E6E6;
  padding: 10px;
  color: black;
}
.check-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.step {
    color: #009FD4; 
    font-weight: bold; 
    background-color: white; 
    margin-bottom: 0;
    padding: 0.4em 0.8em;
}
</style>